<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="pt-40 px-10">
    <div>
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select id="tabs" name="tabs" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
          <option v-for="(tab, i) in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <div @click="setTab(tab.name)" v-for="(tab, i) in tabs" :key="tab.name" :href="tab.href" :class="[tab.current ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer']" :aria-current="tab.current ? 'page' : undefined">
              {{ tab.name }}
            </div>
          </nav>
        </div>
      </div>
    </div>
    <h3 class="text-3xl font-extrabold text-gray-700 sm:text-center">{{tabs.find(e=>e.current).name}}</h3>
    <p class="mt-5 text-xl text-gray-500 sm:text-center pb-4">{{tabs.find(e=>e.current).describe}}</p>
    <div class="min-h-screen	">
      <component :is="tabs.find(e=>e.current).component"></component>
    </div>
  </div>
</template>

<script>

import courses from "../components/admin-tabs/courses";
import packages from "@/components/admin-tabs/packages";
import billing from "../components/admin-tabs/billing";
import users from "../components/admin-tabs/users";
import certificates from "@/components/admin-tabs/certificates";

export default {
  name: 'AdminPanel',
  data(){
    return {
      tabs: [
        { name: 'Courses', describe: 'Add or edit course', current: true, component: 'courses' },
        { name: 'Coupons', describe: 'Add or edit coupons', current: false, component: 'billing' },
        { name: 'Packages', describe: 'Add or edit packages', current: false, component: 'packages' },
        { name: 'Certificates', describe: 'List certificates', current: false, component: 'certificates' },
      ]
    }
  },
  components: {
    courses,
    billing,
    users,
    packages,
    certificates
  },
  methods: {
    setTab(name) {
      this.tabs = this.tabs.map(e=> e.name == name ? {...e, current: true} : {...e, current: false})
    }
  }
}
</script>