<template>
  <div class="pt-4 w-full">
    <div class="flex">
      <p class="text-gray-500 text-sm italic">New question (press Enter to create and select correct answer)</p>
      <clipboard-copy-icon @click="paste" class="ml-4 w-6 h-6 text-gray-500 hover:text-gray-800 cursor-pointer" />
    </div>
    <div class="sm:items-start sm:pt-5" v-for="(q, i) in questions" :key="i">
      <div class="flex w-full justify-between">
        <label class="block text-sm font-medium text-blue-500 sm:mt-px sm:pt-2 w-1/3">
          Question {{ i + 1 }}
        </label>
        <div class="mt-1 w-2/3">
          <textarea
              v-model="q.question"
              :ref="`question${i}`"
                    @keydown.enter="newQuestion($event, i)"
                    @keyup.delete="checkDelete($event, i)"
                    @keydown="writeQuestion($event, i)"
                    type="text" name="first-name" autocomplete="given-course-name"
                    class="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"/>
        </div>
      </div>
      <div v-for="(ans, j) in q.answers" :key="j" class="flex justify-between">
        <label class="block text-sm font-medium text-blue-500 sm:mt-px sm:pt-2 pl-4 w-1/3">
          Answer {{ j + 1 }}
        </label>
        <fieldset class="space-y-5 w-2/3">
          <div class="relative flex items-start w-full">
            <div class="flex items-center h-5 mr-4 pt-4">
              <input
                  @input="toggleAnswer([i, j])"
                  v-model="ans.correct"
                  type="checkbox"
                     class="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"/>
            </div>
            <div class="mt-1 w-full">
              <textarea
                  v-model="ans.text"
                  @keydown.enter="newAnswer($event, i, j)"
                  @keyup.delete="checkDeleteAnswer($event, i, j)"
                  @input="writeAnswer($event, i, j)"
                  :ref="`answer${i}${j}`"
                  type="text"
                  class="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"/>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import {ClipboardCopyIcon} from "@heroicons/vue/solid"
export default {
  name: "questionAdd",
  components: {
    ClipboardCopyIcon
  },
  data() {
    return {
      question: '',
    }
  },
  computed: {
    ...mapState('courses', ['modalFormData']),
    questions() {
      if ('questions' in this.modalFormData) {
        return this.modalFormData.questions
      }
      return [{question: '', answers: [{text: '', correct: false}]}]
    }
  },
  methods: {
    ...mapMutations('courses', ['setModalFormData', 'addNewAnswer', 'addNewQuestion', 'deleteAnswer', 'deleteQuestion', 'toggleAnswer']),
    newQuestion(event, i) {
      // if (!'questions' in this.modalFormData) {
      //   this.setModalFormData({'questions': []})
      // }
      if (this.modalFormData.questions[i].answers.length == 0) {
        this.modalFormData.questions[i].answers.push({text: '', correct: false})
      }
      setTimeout(() => {
        this.$refs[`answer${i}0`].focus()
      }, 1)
      event.preventDefault()
    },
    writeQuestion(event, i) {
      if (!('questions' in this.modalFormData)) {
        this.setModalFormData({'questions': [{question: event.target.value, answers: [{text: '', correct: false}]}]})
      } else if(this.modalFormData.questions.length - 1 < i) {
        this.setModalFormData({'questions': [...this.modalFormData.questions, {question: event.target.value, answers: [{text: '', correct: false}]}]})
      } else {
        this.setModalFormData({questions: this.modalFormData.questions.map((e, j) => {
          if (j == i) {
            return {...this.modalFormData.questions[i], question: event.target.value}
          }
          return e
          } )})
      }
    },
    writeAnswer(event, i, j) {
      this.setModalFormData({questions: this.modalFormData['questions'].map((question, questionIndex) => {
          if (questionIndex == i) {
            return {...this.modalFormData.questions[i], answers: question.answers.map((answer, answer_index) => {
              if (j == answer_index) {
                return {...answer, text: event.target.value}
              }
              return answer
              })}
          }
          return question
        } )})
    },
    checkDelete(e, i) {
      if (this.questions.length > 1 && this.modalFormData.questions[i].question == '') {
        let help = this.modalFormData.questions
        help.splice(i, 1)
        this.setModalFormData(help)
        this.$refs[`answer${i-1}${help[i-1].answers.length - 1}`].focus()
      }
    },
    checkDeleteAnswer(e, i, j) {
      let help = this.modalFormData.questions
      if (help[i].answers[j].text == '') {
        help[i].answers.splice(j, 1)
        this.setModalFormData(help)
        if (j == 0) {
          this.$refs[`question${i}`].focus()
        } else {
          this.$refs[`answer${i}${j - 1}`].focus()
        }
      }
      // if (this.questions.length > 1 && this.modalFormData.questions[i].question == '') {
      //   let help = this.modalFormData.questions
      //   help.splice(i, 1)
      //   this.setModalFormData(help)
      //   this.$refs[`answer${i-1}${help[i-1].answers.length - 1}`].focus()
      // }
    },
    newAnswer(event, i, j) {
      event.preventDefault()

      if (event.target.value.replace(" ", "") != "") {
        this.addNewAnswer(i)
        setTimeout(() => {
          this.$refs[`answer${i}${j + 1}`].focus()
        }, 1)
      } else {
        if (this.modalFormData.questions[i].question.replace(" ", "") == "" && this.modalFormData.questions[i].answers[0].text.replace(" ", "") == "") {
          this.deleteQuestion(i)
          setTimeout(() => {
            this.$refs[`question${i}`].focus()
          }, 1)
        } else {
          this.deleteAnswer([i, j])
          if (this.modalFormData.questions.length - 1 >= i) {
            console.log("pa ovde daas")
            this.addNewQuestion(i)
          }
          setTimeout(() => {
            this.$refs[`question${i + 1}`].focus()
          }, 1)
        }
      }

      console.log(this.modalFormData)
    },
    paste() {
      console.log(this.$store.state.courses.questionsBuffer)
      this.setModalFormData({questions: [...this.modalFormData['questions'], ...this.$store.state.courses.questionsBuffer]})
    }
  }
}
</script>

<style scoped>

</style>