<template>
  <form class="space-y-8 divide-y divide-gray-200">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{title}}
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            {{subtitle}}
          </p>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Name
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input v-model="product_name" type="text" name="first-name" id="name" autocomplete="given-course-name" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Description
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div id="editor"></div>
<!--              <textarea v-model="description" id="about" name="about" rows="3" class="max-w-lg shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md" />-->
              <p class="mt-2 text-sm text-gray-500">Write a few sentences about course.</p>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Course image
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2 cursor-pointer" @click="$refs.image.click()">
              <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div class="space-y-1 text-center">
                  <img :src="previewImageUrl" class="text-blue-300 w-16 h-16 mx-auto" v-if="Object.keys(modalFormData).includes('image')" />
                  <svg v-else class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div class="flex text-sm text-gray-600">
                    <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500">
                      <span>{{ Object.keys(modalFormData).includes('image') ? 'Replace' : 'Upload' }} a file</span>
                      <input name="file-upload" type="file" class="sr-only" ref="image" @change="setFile($event, 'image')"/>
                    </label>
                    <p class="pl-1">or drag and drop</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Additional info
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea v-model="additional_info" type="text" name="add-info" id="add-info" autocomplete="sec-per-page" step="1" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Price (EUR)
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input v-model="product_price" type="number" name="product_price" id="product_price" autocomplete="sec-per-page" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 pb-20">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Choose courses
            </label>
            <fieldset>
              <legend class="text-lg font-medium text-gray-900">Courses</legend>
              <div class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                <div v-for="(course, courseIdx) in $store.state.courses.courses" :key="courseIdx" class="relative flex items-start py-4">
                  <div class="min-w-0 flex-1 text-sm">
                    <label :for="`course-${course.pk}`" class="font-medium text-gray-700 select-none">{{ course.product.name }}</label>
                  </div>
                  <div class="ml-3 flex items-center h-5">
                    <input
                        @change="setCourse($event, course.pk)"
                        :checked="selected[course.pk]"
                        type="checkbox"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
<!--          <course-selector title="Continuation" :courses="continuation"/>-->
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import {mapMutations, mapGetters, mapState} from 'vuex'
import {RefreshIcon, EyeIcon} from '@heroicons/vue/solid'
import questionAdd from "../questions/questionAdd";
import axios from "@/config/http";
import courseSelector from "@/components/ui/courseSelector";

export default {
  components: {
    RefreshIcon,
    questionAdd,
    EyeIcon,
    courseSelector
  },
  data() {
    return {
      initial: [],
      continuation: []
    }
  },
  methods: {
    ...mapMutations('courses', ['setModalFormData']),
    setFile(event, key) {
      let data = {}
      data[key] = event.target.files[0]
      this.setModalFormData(data)
    },
    setCourse(e, pk) {
      console.log(e.target.checked)
      let helper = this.modalFormData.courses
      if (!e.target.checked) {
        let index = helper.indexOf(pk);
        if (index !== -1) {
          helper.splice(index, 1);
        }
      } else {
        helper.push(Number(pk))
      }
      this.setModalFormData({'courses': helper})
    }
  },
  computed: {
    ...mapState('courses', ['modalFormData']),
    selected() {
      console.log(this.$store.state.courses.courses.length)
      if (this.$store.state.courses.courses) {
        let o = {}
        this.$store.state.courses.courses.forEach(e=>{
          // console.log(e, "oll")
          if ( this.modalFormData.courses ) {
            o[e.pk] = this.modalFormData.courses.includes(e.pk)
            console.log(o[e.pk], e.pk)
          } else {
            o[e.pk] = false
          }
        })

        return o
      }
      return []
    },
    product_name: {
      get() {
        return this.modalFormData.product_name
      },
      set(v) {
        this.setModalFormData({'product_name': v})
      }
    },
    description: {
      get() {
        return this.modalFormData.description
      },
      set(v) {
        this.setModalFormData({'description': v})
      }
    },
    seconds_per_page: {
      get() {
        return this.modalFormData.seconds_per_page
      },
      set(v) {
        this.setModalFormData({'seconds_per_page': v})
      }
    },
    seconds_per_question: {
      get() {
        return this.modalFormData.seconds_per_question
      },
      set(v) {
        this.setModalFormData({'seconds_per_question': v})
      }
    },
    additional_info: {
      get() {
        return this.modalFormData.additional_info
      },
      set(v) {
        this.setModalFormData({'additional_info': v})
      }
    },
    product_price: {
      get() {
        return this.modalFormData.product_price
      },
      set(v) {
        this.setModalFormData({'product_price': v})
      }
    },
    answers_to_pass: {
      get() {
        return this.modalFormData.answers_to_pass
      },
      set(v) {
        this.setModalFormData({'answers_to_pass': v})
      }
    },
    question_number: {
      get() {
        return this.modalFormData.question_number
      },
      set(v) {
        this.setModalFormData({'question_number': v})
      }
    },
    course_type: {
      get() {
        return this.modalFormData.course_type
      },
      set(v) {
        this.setModalFormData({'course_type': v})
      }
    },
    previewPdfUrl() {
      try {
        return URL.createObjectURL(this.modalFormData.pdf)
      } catch (e) {
        return this.modalFormData.pdf
      }
    },
    previewCertPdfUrl() {
      try {
        return URL.createObjectURL(this.modalFormData.certificate_pdf)
      } catch (e) {
        return this.modalFormData.certificate_pdf
      }
    },
    previewImageUrl() {
      try {
        return URL.createObjectURL(this.modalFormData.image)
      } catch (e) {
        return this.modalFormData.image
      }
    }
  },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    form: {
      type: Object,
      default: {}
    }
  },
  mounted() {
    this.$store.dispatch('courses/fetchCourses')
    var Delta = Quill.import('delta');
    var toolbarOptions = ['bold', 'italic', 'underline'];

    var quill = new Quill('#editor', {
      theme: 'snow',
      format: 'html',
      content: 'html',
      contentType: 'html',
      modules: {
        toolbar: toolbarOptions
      }
    });
    quill.root.innerHTML = this.description;


    var change = new Delta();

    let _this = this;

    quill.on('text-change', function(delta) {
      // updateHtmlOutput()
      change = change.compose(delta);
      // this.description = quill.root.innerHTML
      _this.setModalFormData({'description': quill.root.innerHTML})
    });
  },
}
</script>