<template>
  <form class="space-y-8 divide-y divide-gray-200">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{title}}
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            {{subtitle}}
          </p>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="first-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Name
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input v-model="product_name" type="text" name="first-name" id="name" autocomplete="given-course-name" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Description
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <div id="editor"></div>
<!--              <textarea v-model="description" id="about" name="about" rows="3" class="max-w-lg shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md" />-->
              <p class="mt-2 text-sm text-gray-500">Write a few sentences about course.</p>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Course image
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2 cursor-pointer" @click="$refs.image.click()">
              <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div class="space-y-1 text-center">
                  <img :src="previewImageUrl" class="text-blue-300 w-16 h-16 mx-auto" v-if="Object.keys(modalFormData).includes('image')" />
                  <svg v-else class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div class="flex text-sm text-gray-600">
                    <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500">
                      <span>{{ Object.keys(modalFormData).includes('image') ? 'Replace' : 'Upload' }} a file</span>
                      <input name="file-upload" type="file" class="sr-only" ref="image" @change="setFile($event, 'image')"/>
                    </label>
                    <p class="pl-1">or drag and drop</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Course pdf file
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2 cursor-pointer" @click="$refs.pdf.click()">
              <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div class="space-y-1 text-center">
                  <refresh-icon class="text-blue-300 w-16 h-16 mx-auto" v-if="Object.keys(modalFormData).includes('pdf')" />
                  <svg v-else class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div class="flex text-sm text-gray-600">
                    <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500">
                      <span>{{ Object.keys(modalFormData).includes('pdf') ? 'Replace' : 'Upload' }} a file</span>
                      <input id="file-upload" name="file-upload" type="file" class="sr-only" ref="pdf" @change="setFile($event, 'pdf')"/>
                    </label>
                    <p class="pl-1">or drag and drop</p>
                  </div>
                  <p class="text-xs text-gray-500">
                    PDF
                  </p>
                </div>
              </div>
            </div>
            <a v-if="Object.keys(modalFormData).includes('pdf')" :href="previewPdfUrl" target="_blank"><eye-icon class="text-blue-300 w-8 h-8 mx-auto hover:text-blue-500"/></a>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Certificate file
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2 cursor-pointer" @click="$refs.pdf_cert.click()">
              <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div class="space-y-1 text-center">
                  <refresh-icon class="text-blue-300 w-16 h-16 mx-auto" v-if="Object.keys(modalFormData).includes('certificate_pdf')" />
                  <svg v-else class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <div class="flex text-sm text-gray-600">
                    <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500">
                      <span>{{ Object.keys(modalFormData).includes('certificate_pdf') ? 'Replace' : 'Upload' }} a file</span>
                      <input id="certificate-file-upload" name="certificate-file-upload" type="file" class="sr-only" ref="pdf_cert" @change="setFile($event, 'certificate_pdf')"/>
                    </label>
                    <p class="pl-1">or drag and drop</p>
                  </div>
                  <p class="text-xs text-gray-500">
                    PDF
                  </p>
                </div>
              </div>
            </div>
            <a v-if="Object.keys(modalFormData).includes('certificate_pdf')" :href="previewCertPdfUrl" target="_blank"><eye-icon class="text-blue-300 w-8 h-8 mx-auto hover:text-blue-500"/></a>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Seconds per page
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input v-model="seconds_per_page" type="number" name="sec-per-page" id="sec-per-page" autocomplete="sec-per-page" step="1" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Seconds per question
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input v-model="seconds_per_question" type="number" name="sec-per-page" id="sec-per-question" autocomplete="sec-per-page" step="1" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Number of answers to pass test
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input v-model="answers_to_pass" type="number" name="sec-per-page" id="answers_to_pass" autocomplete="answers_to_pass" step="1" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Additional info
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea v-model="additional_info" type="text" name="add-info" id="add-info" autocomplete="sec-per-page" step="1" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Certificate prefix
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea v-model="certificate_prefix" type="text" name="add-info" autocomplete="sec-per-page" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Price (EUR)
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input v-model="product_price" type="number" name="product_price" id="product_price" autocomplete="sec-per-page" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              License duration (months)
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input v-model="validity_period_months" type="number" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Number of questions to show
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input v-model="question_number" type="number"  class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Course type
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2 flex space-x-1.5">
              <p class="text-sm font-medium text-gray-700 italic" >Initial</p>
              <!-- This example requires Tailwind CSS v2.0+ -->
              <button type="button" class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" role="switch" aria-checked="false" @click="switchCourseType">
                <span aria-hidden="true" class="pointer-events-none absolute bg-white w-full h-full rounded-md"></span>
                <!-- Enabled: "bg-blue-600", Not Enabled: "bg-gray-200" -->
                <span aria-hidden="true" :class="`${course_type == 'initial' ? 'bg-gray-200' : 'bg-blue-600'} pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200`"></span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span aria-hidden="true" :class="`${course_type == 'initial' ? 'translate-x-0' : 'translate-x-5'} pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200`"></span>
              </button>
              <p class="text-sm font-medium text-gray-700 italic">Continuation</p>
            </div>
          </div>

          <div class="sm:border-t sm:border-gray-200 sm:pt-5 sm:w-2/3">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Questions
            </label>
            <question-add class="w-full"></question-add>
<!--            <div class="mt-1 sm:mt-0 sm:col-span-2">-->
<!--              <input v-model="product_price" type="number" name="product_price" id="product_price" autocomplete="sec-per-page" class="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import {mapMutations, mapGetters, mapState} from 'vuex'
import {RefreshIcon, EyeIcon} from '@heroicons/vue/solid'
import questionAdd from "../questions/questionAdd";

export default {
  components: {
    RefreshIcon,
    questionAdd,
    EyeIcon
  },
  data() {
    return {
      aa: null
    }
  },
  methods: {
    ...mapMutations('courses', ['setModalFormData']),
    setFile(event, key) {
      let data = {}
      data[key] = event.target.files[0]
      this.setModalFormData(data)
    },
    switchCourseType() {
      this.course_type = this.course_type == 'initial' ? 'continuation' : 'initial'
    }
  },
  computed: {
    ...mapState('courses', ['modalFormData']),
    product_name: {
      get() {
        return this.modalFormData.product_name
      },
      set(v) {
        this.setModalFormData({'product_name': v})
      }
    },
    description: {
      get() {
        return this.modalFormData.description
      },
      set(v) {
        this.setModalFormData({'description': v})
      }
    },
    seconds_per_page: {
      get() {
        return this.modalFormData.seconds_per_page
      },
      set(v) {
        this.setModalFormData({'seconds_per_page': v})
      }
    },
    validity_period_months: {
      get() {
        return this.modalFormData.validity_period_months
      },
      set(v) {
        this.setModalFormData({'validity_period_months': v})
      }
    },
    certificate_prefix: {
      get() {
        return this.modalFormData.certificate_prefix
      },
      set(v) {
        this.setModalFormData({'certificate_prefix': v})
      }
    },
    seconds_per_question: {
      get() {
        return this.modalFormData.seconds_per_question
      },
      set(v) {
        this.setModalFormData({'seconds_per_question': v})
      }
    },
    additional_info: {
      get() {
        return this.modalFormData.additional_info
      },
      set(v) {
        this.setModalFormData({'additional_info': v})
      }
    },
    product_price: {
      get() {
        return this.modalFormData.product_price
      },
      set(v) {
        this.setModalFormData({'product_price': v})
      }
    },
    answers_to_pass: {
      get() {
        return this.modalFormData.answers_to_pass
      },
      set(v) {
        this.setModalFormData({'answers_to_pass': v})
      }
    },
    question_number: {
      get() {
        return this.modalFormData.question_number
      },
      set(v) {
        this.setModalFormData({'question_number': v})
      }
    },
    course_type: {
      get() {
        return this.modalFormData.course_type
      },
      set(v) {
        this.setModalFormData({'course_type': v})
      }
    },
    previewPdfUrl() {
      try {
        return URL.createObjectURL(this.modalFormData.pdf)
      } catch (e) {
        return this.modalFormData.pdf
      }
    },
    previewCertPdfUrl() {
      try {
        return URL.createObjectURL(this.modalFormData.certificate_pdf)
      } catch (e) {
        return this.modalFormData.certificate_pdf
      }
    },
    previewImageUrl() {
      try {
        return URL.createObjectURL(this.modalFormData.image)
      } catch (e) {
        return this.modalFormData.image
      }
    }
  },
  mounted() {
    if (this.course_type != 'initial' && this.course_type != 'continuation') {
      this.course_type = 'initial'
    }

    var Delta = Quill.import('delta');
    var toolbarOptions = ['bold', 'italic', 'underline'];

    var quill = new Quill('#editor', {
      theme: 'snow',
      format: 'html',
      content: 'html',
      contentType: 'html',
      modules: {
        toolbar: toolbarOptions
      }
    });
    quill.root.innerHTML = this.description;


    var change = new Delta();

    let _this = this;

    quill.on('text-change', function(delta) {
      // updateHtmlOutput()
      change = change.compose(delta);
      // this.description = quill.root.innerHTML
      _this.setModalFormData({'description': quill.root.innerHTML})
    });
  },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    form: {
      type: Object,
      default: {}
    }
  },
}
</script>