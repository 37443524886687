<template>
  <div class="flex flex-col">
    <button @click="download" type="button" style="max-width: 200px;" class="mb-10 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
      Download xlsx
      <download-icon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
    </button>
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name and Surname
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date and place of birth
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                e-mail address
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Course/Training name
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Issue date
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Issue place
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Certificate no.
              </th>

              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Issued by
              </th>

              <th scope="col" class="relative px-6 py-3">
                <span>File</span>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(cert, certIdx) in certs" :key="cert.pk" :class="certIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ cert.user.first_name }} {{cert.user.last_name}}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ cert.user.birthday }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ cert.user.email }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ cert.listening.course.product.name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {{ cert.date }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                E-learning
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ cert.code }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                N. Glumicic – QM
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div class="w-0 flex-1 flex items-center">
                        <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span class="ml-2 flex-1 w-0 truncate">
                    {{cert.file.split('certificates/')[1]}}
                  </span>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <button @click="open(cert.file)" class="font-medium text-blue-600 hover:text-blue-500">
                          Open
                        </button>
                      </div>
                    </li>
                  </ul>
                </dd>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {DownloadIcon} from '@heroicons/vue/solid'

const people = [
  { name: 'Jane Cooper', title: 'Regional Paradigm Technician', role: 'Admin', email: 'jane.cooper@example.com' },
  { name: 'Cody Fisher', title: 'Product Directives Officer', role: 'Owner', email: 'cody.fisher@example.com' },
  // More people...
]

export default {
  components: {
    DownloadIcon
  },
  setup() {
    return {
      people,
    }
  },
  data() {
    return {
      certs: []
    }
  },
  methods: {
    open(url) {
      window.open(process.env.VUE_APP_ROOT_API.split('api')[0] + url, '_blank').focus();
    },
    download() {
      this.axios
          .get(`service/cert-report/`, {
            responseType: 'blob'
          })
          .then(response => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.xlsx');
            document.body.appendChild(link);
            link.click();
          })
    }
  },
  async mounted() {
    let {data} = await this.axios.get('service/certificates?ordering=-pk&all=true')
    this.certs = data
  }
}
</script>